import { defaultLocale } from 'i18n/config';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ValidationError, getSupplierUrl } from '@bridebook/toolbox/src';
import { env } from '@bridebook/toolbox/src/env';
import Button from '@bridebook/ui/src/components/bbcommon/buttons/button';
import Input from '@bridebook/ui/src/components/bbcommon/inputs/input/input';
import TextField from '@bridebook/ui/src/components/bbcommon/inputs/text-field/text-field';
import LoadingCircle from '@bridebook/ui/src/components/bbcommon/loading-circle/loading-circle';
import Snackbar from '@bridebook/ui/src/components/bbcommon/snackbar';
import A from '@bridebook/ui/src/components/bbcommon/tags/a/a';
import Box from '@bridebook/ui/src/components/fela/Box';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import * as Sentry from '@sentry/nextjs';
import WeddingDateField from 'components/iframe-page/wedding-date-field';
import { trackClickedToSendEnquiry } from 'lib/enquiry-form/analytics';
import {
  enquiryFormFieldsState,
  initialState,
  weddingFieldsToSendSelector,
} from 'lib/enquiry-form/atoms';
import { getDefaultFields, getOptionalFields } from 'lib/enquiry-form/enquiry-form';
import submitEnquiry, { validate } from 'lib/enquiry-form/submit-enquiry';
import verifyRecaptcha from 'lib/enquiry-form/verify-recaptcha';
import { Locales } from 'lib/i18n/constants';
import getCouplesideLocale from 'lib/i18n/get-coupleside-locale';
import { supplierState } from 'lib/supplier/atoms';
import { fetchSupplierPromise } from 'lib/supplier/fetch-supplier';
import componentStyles from './enquiry-form.style';

export interface ISnackbarState {
  mode: string;
  show: boolean;
  text: string;
}

const IframePage = () => {
  const { t } = useTranslation('common');
  const { locale = defaultLocale } = useRouter();
  const coupleSideLocale = getCouplesideLocale(locale as Locales);
  const [error, setError] = useState<null | ValidationError>(null);
  const [supplier, setSupplier] = useRecoilState(supplierState);
  const [enquiryFormFields, setEnquiryFormFields] = useRecoilState(enquiryFormFieldsState);
  const [loading, setLoading] = useState(true);
  const [snackbarState, setSnackbarState] = useState({
    show: false,
    mode: 'success',
    text: t('enquirySent'),
  });
  const { query } = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const weddingFields = useRecoilValue(weddingFieldsToSendSelector);
  const recaptchaRef = useRef(null);

  const defaultFields = getDefaultFields();
  const optionalFields = getOptionalFields();

  const toggleSnackBar = (newState: ISnackbarState) => {
    setSnackbarState(newState);

    setTimeout(() => {
      setSnackbarState({ ...newState, show: false });
    }, 3000);
  };

  // http://localhost:3000/?publicId=eeaXqR9Rgl&fields=phone&fields=partnerName2&fields=guestsInitialTarget&fields=weddingDate&fields=message
  const fields = query?.fields && Array.isArray(query?.fields) ? query?.fields : [];
  const styles = componentStyles();

  // Fetch firestore supplier
  useEffect(() => {
    if (query.publicId && !supplier) {
      const startFetchingSupplier = async () => {
        const supplierResponse = await fetchSupplierPromise(query.publicId as string);
        if (supplierResponse) {
          setSupplier(supplierResponse);
          setLoading(false);
        }
      };
      startFetchingSupplier();
    }
  }, [query.publicId, setSupplier, supplier]);

  const supplierUrl = getSupplierUrl(supplier);
  const disabled = loading || !supplier;

  const simpleTextOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError(null);
    const saveValue = name === optionalFields.guestsInitialTarget.name ? parseInt(value) : value;
    setEnquiryFormFields({ ...enquiryFormFields, [name]: saveValue });
  };

  const retry = async () => {
    try {
      await recaptchaRef.current.executeAsync();
      await submitEnquiry(supplier, weddingFields, setLoading, toggleSnackBar, enquiryFormFields);
      setEnquiryFormFields(initialState);
    } catch (e) {
      Sentry.captureException(e);
      toggleSnackBar({
        show: true,
        mode: 'error',
        text: t('couldntSendEnquiry'),
      });
      setLoading(false);
    }
  };

  const validateAndSubmit = async () => {
    try {
      if (!supplier) {
        throw new Error('supplier is null');
      }
      trackClickedToSendEnquiry(weddingFields, '', supplier, enquiryFormFields);
      setError(null);
      validate(enquiryFormFields);
      setLoading(true);
      const token = await executeRecaptcha('enquiry_widget');
      const recaptchaValid = await verifyRecaptcha(token);
      if (!recaptchaValid) {
        throw new Error(t('recaptchaNotValid'));
      }

      await submitEnquiry(supplier, weddingFields, setLoading, toggleSnackBar, enquiryFormFields);
      setEnquiryFormFields(initialState);
    } catch (e) {
      if (e instanceof ValidationError) {
        setError(e);
      } else {
        retry();
      }
    }
  };

  return (
    <Box as={'form'} onSubmit={validateAndSubmit}>
      <Box style={styles.formWrapper}>
        <Snackbar show={snackbarState.show} mode={snackbarState.mode} text={snackbarState.text} />
        <Box style={styles.heading}>{t('enquire.about.wedding')}</Box>
        {loading ? (
          <Box style={styles.loadingWrap}>
            <LoadingCircle size={50} color="blueCrush" />

            {/*{query && !query.publicId && (*/}
            {/*  <Box>*/}
            {/*    There seems to be a problem with your enquiry widget. Please check the code at*/}
            {/*    business.bridebook.com/enquiry-widget*/}
            {/*  </Box>*/}
            {/*)}*/}
          </Box>
        ) : (
          <Box>
            {/* Default required fields */}
            <Box style={styles.formField}>
              <Input
                name={defaultFields.email.name}
                placeholder={defaultFields.email.placeholder}
                type={defaultFields.email.type}
                pattern={defaultFields.email.pattern}
                required
                value={enquiryFormFields['email']}
                onChange={simpleTextOnChange}
                error={error && error.prop === defaultFields.email.name ? error : null}
              />
            </Box>
            <Box style={styles.formField}>
              <Input
                name={defaultFields.partnerName1.name}
                placeholder={defaultFields.partnerName1.placeholder}
                type={defaultFields.partnerName1.type}
                pattern={defaultFields.partnerName1.pattern}
                required
                value={enquiryFormFields.partnerName1 || ''}
                onChange={simpleTextOnChange}
                error={error && error.prop === defaultFields.partnerName1.name ? error : null}
              />
            </Box>
            {/* Phone field */}
            {fields.includes('phone') && (
              <Box style={styles.formField}>
                <Input
                  name={optionalFields.phone.name}
                  placeholder={optionalFields.phone.placeholder}
                  type={optionalFields.phone.type}
                  pattern={optionalFields.phone.pattern}
                  value={enquiryFormFields.phone}
                  onChange={simpleTextOnChange}
                  error={error && error.prop === optionalFields.phone.name ? error : null}
                />
              </Box>
            )}
            {/* Partner 2 name field */}
            {fields.includes('partnerName2') && (
              <Box style={styles.formField}>
                <Input
                  name={optionalFields.partnerName2.name}
                  placeholder={optionalFields.partnerName2.placeholder}
                  type={optionalFields.partnerName2.type}
                  pattern={optionalFields.partnerName2.pattern}
                  value={enquiryFormFields.partnerName2 || ''}
                  onChange={simpleTextOnChange}
                  error={error && error.prop === optionalFields.partnerName2.name ? error : null}
                />
              </Box>
            )}
            {/*Guest number field*/}
            {fields.includes('guestsInitialTarget') && (
              <Box style={styles.formField}>
                <Input
                  min="0"
                  inputMode="numeric"
                  name={optionalFields.guestsInitialTarget.name}
                  type={optionalFields.guestsInitialTarget.type}
                  placeholder={optionalFields.guestsInitialTarget.placeholder}
                  pattern={optionalFields.guestsInitialTarget.pattern}
                  value={enquiryFormFields.guestsInitialTarget}
                  onChange={simpleTextOnChange}
                  error={
                    error && error.prop === optionalFields.guestsInitialTarget.name ? error : null
                  }
                />
              </Box>
            )}
            {/*Wedding date field*/}
            {fields.includes('weddingDate') && (
              <WeddingDateField {...{ supplier, setError, error }} />
            )}
            {/*Message field*/}
            {fields.includes('message') && (
              <Box style={styles.formField}>
                <TextField
                  name={optionalFields.message.name}
                  placeholder={optionalFields.message.placeholder}
                  value={enquiryFormFields.message}
                  rows={7}
                  onChange={simpleTextOnChange}
                  error={error && error.prop === optionalFields.message.name ? error : null}
                />
              </Box>
            )}
          </Box>
        )}

        <ReCAPTCHA
          size="invisible"
          sitekey="6LdaONIZAAAAAAatntiJKEKc_bUx1SUDudG4e4hA"
          ref={recaptchaRef}
        />

        <Box>
          <Button
            name="button-send-enquiry"
            id="button-send-enquiry"
            type="submit"
            theme="secondary"
            size="big"
            width="block"
            disabled={disabled}
            text={t('sendEnquiry')}
            onClick={validateAndSubmit}
          />
        </Box>
        <Box style={styles.footer}>
          <Box style={styles.terms}>
            <Trans ns="common" i18nKey="agreeTerms">
              By sending enquiries you agree to our{' '}
              <A
                href={`${env.COUPLESIDE_URL}/${coupleSideLocale}/terms`}
                target="_blank"
                style={styles.termsButton}>
                terms
              </A>
            </Trans>
          </Box>
          <Box style={styles.poweredByWrap}>
            <Box style={styles.poweredBy}>{t('PoweredBy')}</Box>
            <A href={`${env.COUPLESIDE_URL}/${coupleSideLocale}${supplierUrl}`} target="_blank">
              <BridebookLogo color="blueCrush" width={90} />
            </A>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IframePage;
